<template>
  <div class="editMember">
    <div class="namebox">
      <p>资讯名称:</p>
      <el-input v-model="title" placeholder="请输入资讯名称"></el-input>
    </div>
    <div class="namebox">
      <p>阅读量:</p>
      <el-input v-model="readingVolume" placeholder="请输入阅读量"></el-input>
    </div>
    <div class="namebox">
      <p>资讯封面：</p>
      <upload-img
        :fixedNumber="[16, 9]"
        fixedName="16:9"
        accept="image/*"
        tip="推荐图片尺寸宽高比16:9"
        uploadName="上传封面"
        @uploadInfo="uploadInfo"
        :fixedFlag="true"
      />
    </div>
    <div>
      <!--富文本编辑器组件-->
      <quill-editor
        v-model="content"
        ref="QuillEditor"
        class="editor"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      >
      </quill-editor>
    </div>
    <div class="handlebox">
      <el-button type="primary" class="blue-btn" @click="handleClick"
        >{{ editShow ? "编辑" : "添加" }}资讯</el-button
      >
    </div>
  </div>
</template>
<script>
import { uploadOss } from "@/api/login";
import { addModifyNews } from "@/api/news";
import UploadImg from "../../../components/UploadImg";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import * as Quill from "quill"; //引入编辑器
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", ImageResize);
import { ImageExtend } from "quill-image-extend-module";
import Video from "./video";
Quill.register("modules/ImageExtend", ImageExtend);
Quill.register("modules/imageDrop", ImageDrop);
Quill.register(Video, true);
var sizes = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px"];
var Size = Quill.import("formats/size");
Size.whitelist = sizes;
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
  ["blockquote", "code-block"], //引用，代码块
  // [{ 'header': 1 }, { 'header': 2 }],               // 几级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
  // [{ 'script': 'sub' }, { 'script': 'super' }],     // 下角标，上角标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{ 'direction': 'rtl' }],                         // 文字输入方向
  [{ size: sizes }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 颜色选择
  [
    {
      font: [
        "SimSun",
        "SimHei",
        "Microsoft-YaHei",
        "KaiTi",
        "FangSong",
        "Arial",
      ],
    },
  ], // 字体
  [{ align: [] }], // 居中
  ["clean"], // 清除样式,
  ["link", "image", "video"], // 链接、图片、视频
];

// toolbar标题
const titleConfig = [
  { Choice: ".ql-insertMetric", title: "跳转配置" },
  { Choice: ".ql-bold", title: "加粗" },
  { Choice: ".ql-italic", title: "斜体" },
  { Choice: ".ql-underline", title: "下划线" },
  { Choice: ".ql-header", title: "段落格式" },
  { Choice: ".ql-strike", title: "删除线" },
  { Choice: ".ql-blockquote", title: "块引用" },
  { Choice: ".ql-code", title: "插入代码" },
  { Choice: ".ql-code-block", title: "插入代码段" },
  { Choice: ".ql-font", title: "字体" },
  { Choice: ".ql-size", title: "字体大小" },
  { Choice: ".ql-list[value='ordered']", title: "编号列表" },
  { Choice: ".ql-list[value='bullet']", title: "项目列表" },
  { Choice: ".ql-direction", title: "文本方向" },
  { Choice: ".ql-header[value='1']", title: "h1" },
  { Choice: ".ql-header[value='2']", title: "h2" },
  { Choice: ".ql-align", title: "对齐方式" },
  { Choice: ".ql-color", title: "字体颜色" },
  { Choice: ".ql-background", title: "背景颜色" },
  { Choice: ".ql-image", title: "图像" },
  { Choice: ".ql-video", title: "视频" },
  { Choice: ".ql-link", title: "添加链接" },
  { Choice: ".ql-formula", title: "插入公式" },
  { Choice: ".ql-clean", title: "清除字体格式" },
  { Choice: ".ql-script[value='sub']", title: "下标" },
  { Choice: ".ql-script[value='super']", title: "上标" },
  { Choice: ".ql-indent[value='-1']", title: "向左缩进" },
  { Choice: ".ql-indent[value='+1']", title: "向右缩进" },
  { Choice: ".ql-header .ql-picker-label", title: "标题大小" },
  { Choice: ".ql-header .ql-picker-item[data-value='1']", title: "标题一" },
  { Choice: ".ql-header .ql-picker-item[data-value='2']", title: "标题二" },
  { Choice: ".ql-header .ql-picker-item[data-value='3']", title: "标题三" },
  { Choice: ".ql-header .ql-picker-item[data-value='4']", title: "标题四" },
  { Choice: ".ql-header .ql-picker-item[data-value='5']", title: "标题五" },
  { Choice: ".ql-header .ql-picker-item[data-value='6']", title: "标题六" },
  { Choice: ".ql-header .ql-picker-item:last-child", title: "标准" },
  { Choice: ".ql-align .ql-picker-item:first-child", title: "居左对齐" },
  {
    Choice: ".ql-align .ql-picker-item[data-value='center']",
    title: "居中对齐",
  },
  {
    Choice: ".ql-align .ql-picker-item[data-value='right']",
    title: "居右对齐",
  },
  {
    Choice: ".ql-align .ql-picker-item[data-value='justify']",
    title: "两端对齐",
  },
];
export default {
  name: "addarticle",
  components: {
    quillEditor,
    UploadImg,
  },
  data() {
    return {
      editShow: false,
      title: null,
      readingVolume: null,
      img: null,
      id: null,
      content: "",

      Quill: null,
      editorOption: {
        placeholder: "请在这里输入",
        theme: "snow", //主题 snow/bubble
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: this.imageHandler,
              video: this.videoHandler,
            },
          },
          // 图片拖拽上传
          imageDrop: true,
          // 放大缩小
          imageResize: {
            displaySize: true,
          },
        },
      },
    };
  },
  created() {
    // if (this.$route.query.id) {
    //   this.id = this.$route.query.id;
    //   this.editShow = true;
    //   this.agreementName = this.$route.query.name;
    //   getInfo({ agreementName: this.$route.query.name }).then((res) => {
    //     this.content = res.result;
    //   });
    // } else {
    //   this.editShow = false;
    // }
  },
  mounted() {
    // this.initButton();
    this.initTitle();
    // deleteNews({ id: 10 }).then((res) => {});
  },
  computed: {
    editor() {
      return this.$refs.QuillEditor.quill;
    },
  },
  methods: {
    // 获取上传封面
    uploadInfo(info) {
      this.img = info;
    },
    getToken() {
      if (localStorage.getItem("userInfo")) {
        return JSON.parse(localStorage.getItem("userInfo")).token;
      }
    },

    initTitle() {
      document.getElementsByClassName("ql-editor")[0].dataset.placeholder = "";
      for (let item of titleConfig) {
        let tip = document.querySelector(".quill-editor " + item.Choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    },

    // 自定义按钮内容初始化
    // initButton() {
    //   const editorButton = document.querySelector(".ql-myButton");
    //   editorButton.innerHTML =
    //     "<i class='el-icon-picture' style='font-size: 18px;color:black'></i>";
    // },

    // 失去焦点
    onEditorBlur(editor) {
      console.log("editor :>> ", editor);
    },

    // 获得焦点
    onEditorFocus(editor) {
      console.log("editor :>> ", editor);
    },

    // 开始
    onEditorReady(editor) {
      console.log("editor :>> ", editor);
    },
    imageHandler() {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute(
        "accept",
        "image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
      );
      input.click();
      // 监听上传
      input.onchange = () => {
        let file = input.files[0];
        if (/^image\//.test(file.type)) {
          this.saveImage(file);
        } else {
          // Toast("只能上传图片哦");
        }
      };
    },
    saveImage(file) {
      // console.log("file :>> ", file);
      //调用后端提供的oss接口
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileDir", "develop");
      uploadOss(formData)
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            //   const file = res.data
            this.insertImage(res.result);
            return;
          }
          // failure("上传失败");
        })
        .catch(() => {
          // failure("上传出错");
        });
    },
    insertImage(url) {
      console.log("url :>> ", url);
      console.log("this.Quill :>> ", this.editor);
      let range = this.editor.getSelection();
      console.log("range :>> ", range);
      this.editor.insertEmbed(range.index, "image", url);
    },
    videoHandler() {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "video/*");
      input.click();
      // 监听上传
      input.onchange = () => {
        let file = input.files[0];
        if (/^video\//.test(file.type)) {
          this.saveVideo(file);
          // console.log("file :>> ", file);
        } else {
          // Toast("只能上传图片哦");
        }
      };
    },
    saveVideo(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileDir", "video");
      uploadOss(formData)
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            //   const file = res.data
            this.insertVideo(res.result);
            return;
          }
          // failure("上传失败");
        })
        .catch(() => {
          // failure("上传出错");
        });
    },
    insertVideo(url) {
      let range = this.editor.getSelection();
      // console.log("range :>> ", range);
      this.editor.insertEmbed(range.index, "video", url);
    },
    handleClick() {
      // if (this.editShow) {
      //   this.edit();
      // } else {
      this.add();
      // }
    },
    // 保存富文本内容-保存字符串
    async add() {
      // if (!this.agreementName) {
      //   this.$message.error("请输入协议名称");
      //   return;
      // }
      // if (!this.content) {
      //   this.$message.error("请输入协议内容");
      //   return;
      // }
      const params = {
        img: this.img,
        isRecommend: 0,
        newsType: 1,
        readingVolume: this.readingVolume,
        sortNumber: 0,
        status: 0,
        title: this.title,
        info: this.content,
      };
      console.log("params :>> ", params);

      await addModifyNews(params);
      this.$message.success("添加资讯成功");
      this.$router.go(-1);
      // console.log("params :>> ", this.info);
    },
    // async edit() {
    //   if (!this.agreementName) {
    //     this.$message.error("请输入协议名称");
    //     return;
    //   }
    //   if (!this.content) {
    //     this.$message.error("请输入协议内容");
    //     return;
    //   }
    //   const params = {
    //     agreementName: this.agreementName,
    //     info: this.content,
    //     id: this.id,
    //   };
    //   console.log("params :>> ", params);
    //   await editAgreement(params);
    //   this.$message.success("修改协议成功");
    //   this.$router.go(-1);
    // },
  },
};
</script>
<style lang="scss" scoped>
.editMember {
  ::v-deep .namebox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .el-input {
      width: 256px;
      margin-left: 20px;
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #86909c;
    }
    .el-input__inner {
      background: #f7f8fa;
      border: none;
      color: #86909c;
    }
  }
  .handlebox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 126px 0;
    ::v-deep .el-button {
      width: 156px;
      padding: 10px 0;
    }
  }
}
</style>
<style>
p {
  margin: 10px;
}

.edit_container,
.quill-editor {
  height: 500px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14" !important;
  font-size: 14px;
}

.ql-size-14px {
  font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16" !important;
  font-size: 16px;
}

.ql-size-16px {
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18" !important;
  font-size: 18px;
}

.ql-size-18px {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20" !important;
  font-size: 20px;
}

.ql-size-20px {
  font-size: 20px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22" !important;
  font-size: 22px;
}

.ql-size-22px {
  font-size: 22px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26" !important;
  font-size: 26px;
}

.ql-size-26px {
  font-size: 26px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28" !important;
  font-size: 28px;
}

.ql-size-28px {
  font-size: 28px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30" !important;
  font-size: 30px;
}

.ql-size-30px {
  font-size: 30px;
}
</style>
